.App {
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-logo {
  width: 360px;
  margin-top: -200px;
}

.App-header {
  color: white;
}

.App-header > p {
  text-align: center;
}
